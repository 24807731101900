import "./App.css"
import Tickbox from "./Tickbox";

function Sat() {
    return (
        <div className="">
            <div className="date-text">SAT</div>
            <div className="task">
                <div>Exercise</div> 
                <div>Time: 1 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_sat11"}/>
                <Tickbox label={"已完成"} storageKey={"key_sat12"}/>
            </div>

            <div className="task">
                <div>Exercise</div> 
                <div>Time: 1 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_sat21"}/>
                <Tickbox label={"已完成"} storageKey={"key_sat22"}/>
            </div>

            <div className="task">
                <div>Exercise</div> 
                <div>Time: 1 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_sat31"}/>
                <Tickbox label={"已完成"} storageKey={"key_sat32"}/>
            </div>

            <div className="task">
                <div>Exercise</div> 
                <div>Time: 1 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_sat41"}/>
                <Tickbox label={"已完成"} storageKey={"key_sat42"}/>
            </div>


        </div>

    );
}

export default Sat