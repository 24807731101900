import "./App.css"
import Mon from "./Mon"
import Tue from "./Tue";
import Wed from "./Wed";
import Thu from "./Thu";
import Fri from "./Fri";
import Sat from "./Sat";
import Sun from "./Sun";
import { clearAllCheckedTickboxes } from './utils';
import { useState } from "react";


function App() {
  const [show, setShow] = useState(false)
  function handleDialogue () {
    setShow(!show);
  }
  function handleClear () {
    setShow(!show);
    clearAllCheckedTickboxes();
  }
  function Dialogue() {
    return (
      <div className="dialogue-container">
        <div>Confirm to clear</div>
        <div>
          <button onClick={handleClear}>确认</button>
          <button onClick={handleDialogue}>取消</button>
        </div>
      </div>
    )
  }
  function showDialogue() {
    if (show) {
      return <Dialogue />
    }
  }
  return (
    <div className="App">
      <div className="app-container">
        <div className="title">Timetable</div>
        <div className="task-container">
          <Mon />
          <Tue />
          <Wed />
          <Thu />
          <Fri />
          <Sat />
          <Sun />
        </div>
        {showDialogue()}
        {!show && <button onClick={ handleDialogue }>clear all</button>}
      </div>      
    </div>
  );
}

export default App;
