import "./App.css"
import Tickbox from "./Tickbox";

function Tue() {
    const show1 = () => {
        alert('ALAN GILBERT BUILDING, Collaborative Learning Space Floor:1 Room:101')
    }
    const show2 = () => {
        alert('SIDNEY MYER ASIA CENTRE, Carrillo Gantner Theatre & Event Venue Floor:0.1 Room:B02')
    }
    const show3 = () => {
        alert('THE SPOT, Copland Theatre & Event Venue Floor:0.1 Room:B01')
    }
    const show4 = () => {
        alert('JOHN MEDLEY BUILDING, Collaborative Learning Space Floor:0 Room:WG03')
    }

    return (
        <div className="">
            <div className="date-text">TUE</div>

            <div className="task" style={{ '--border-color': '#0f52c5'}}>
                <div onClick={show1}>ADBS tutorial</div> 
                <div>Time: 9:00-10:00</div>
                <Tickbox label={"进行中"} storageKey={"key_tue21"}/>
                <Tickbox label={"已完成"} storageKey={"key_tue22"}/>
            </div>

            <div className="task" style={{ '--border-color': '#2fa6dd'}}>
                <div onClick={show2}>IML lecture 1</div> 
                <div>Time: 13:00-14:00</div>
                <Tickbox label={"进行中"} storageKey={"key_tue21"}/>
                <Tickbox label={"已完成"} storageKey={"key_tue22"}/>
            </div>

            <div className="task" style={{ '--border-color': '#2fa6dd'}}>
                <div onClick={show3}>ADBS lecture 1</div> 
                <div>Time: 14:15-15:15</div>
                <Tickbox label={"进行中"} storageKey={"key_tue31"}/>
                <Tickbox label={"已完成"} storageKey={"key_tue32"}/>
            </div>

            <div className="task" style={{ '--border-color': '#0f52c5'}}>
                <div onClick={show4}>DS tutorial</div> 
                <div>Time: 16:15-17:15</div>
                <Tickbox label={"进行中"} storageKey={"key_tue41"}/>
                <Tickbox label={"已完成"} storageKey={"key_tue42"}/>
            </div>

        </div>

    );

}

export default Tue