import "./App.css"
import Tickbox from "./Tickbox";


function Wed() {
    const show1 = () => {
        alert('Electric & Electronic Engineering Buidling, 122-Engineering Computer Lab (36)')
    }
    const show2 = () => {
        alert('SIDNEY MYER ASIA CENTRE, Carrillo Gantner Theatre & Event Venue Floor:0.1 Room:B02')
    }
    return (
        <div className="">
            <div className="date-text">WED</div>

            <div className="task" style={{ '--border-color': '#0f52c5'}}>
                <div onClick={show1}>IML tutorial</div> 
                <div>Time: 11:00-12:00</div>
                <Tickbox label={"进行中"} storageKey={"key_wed21"}/>
                <Tickbox label={"已完成"} storageKey={"key_wed22"}/>
            </div>

            <div className="task" style={{ '--border-color': '#2fa6dd'}}>
                <div onClick={show2}>CCC lecture</div> 
                <div>Time: 15:15-17:15</div>
                <Tickbox label={"进行中"} storageKey={"key_wed11"}/>
                <Tickbox label={"已完成"} storageKey={"key_wed12"}/>
            </div>

        </div>

    );
}

export default Wed