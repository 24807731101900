export const clearAllCheckedTickboxes = () => {

  while (localStorage.length) {
    const key = localStorage.key(0);
    // if (key.startsWith('key_') && localStorage.getItem(key) === 'true') {
    //   localStorage.removeItem(key);
    // }
    localStorage.removeItem(key);
  }
};
  