import "./App.css"
import Tickbox from "./Tickbox";

function Thu() {
    const show1 = () => {
        alert('PETER HALL BUILDING, JH Michell Theatre Floor:0 Room:G01')
    }
    return (
        <div className="">
            <div className="date-text">THU</div>

            <div className="task" style={{ '--border-color': '#2fa6dd'}}>
                <div onClick={show1}>IML lecture 2</div> 
                <div>Time: 14:15-15:15</div>
                <Tickbox label={"进行中"} storageKey={"key_thu21"}/>
                <Tickbox label={"已完成"} storageKey={"key_thu22"}/>
            </div>


        </div>

    );
}

export default Thu