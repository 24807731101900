import "./App.css"
import Tickbox from "./Tickbox";


function Sun() {
    return (
        <div className="">
            <div className="date-text">SUN</div>
            <div className="task" style={{ '--border-color': '#fe302c'}}>
                <div>Self-study</div> 
                <div>Time: 2 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_sun11"}/>
                <Tickbox label={"已完成"} storageKey={"key_sun12"}/>
            </div>

            <div className="task" style={{ '--border-color': '#fe302c'}}>
                <div>Self-study</div> 
                <div>Time: 2 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_sun21"}/>
                <Tickbox label={"已完成"} storageKey={"key_sun22"}/>
            </div>

            <div className="task" style={{ '--border-color': '#fe302c'}}>
                <div>Self-study</div> 
                <div>Time: 2 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_sun31"}/>
                <Tickbox label={"已完成"} storageKey={"key_sun32"}/>
            </div>

            <div className="task" style={{ '--border-color': '#fe302c'}}>
                <div>Self-study</div> 
                <div>Time: 2 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_sun41"}/>
                <Tickbox label={"已完成"} storageKey={"key_sun42"}/>
            </div>

        </div>

    );
}

export default Sun