import "./App.css"
import Tickbox from "./Tickbox";

function Mon() {
    return (
        <div className="">
            <div className="date-text">MON</div>
            <div className="task" style={{ '--border-color': '#fe302c'}}>
                <div>IML review</div> 
                <div>Time: SUN 2 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_mon11"}/>
                <Tickbox label={"已完成"} storageKey={"key_mon12"}/>
            </div>

            <div className="task" style={{ '--border-color': '#fe302c'}}>
                <div>CCC review</div> 
                <div>Time: SAT 2 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_mon21"}/>
                <Tickbox label={"已完成"} storageKey={"key_mon22"}/>
            </div>

            <div className="task" style={{ '--border-color': '#fe302c'}}>
                <div>DS review</div> 
                <div>Time: SAT 2 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_mon31"}/>
                <Tickbox label={"已完成"} storageKey={"key_mon32"}/>
            </div>

            <div className="task" style={{ '--border-color': '#fe302c'}}>
                <div>ADBS review</div> 
                <div>Time: THU 2 hours</div>
                <Tickbox label={"进行中"} storageKey={"key_mon41"}/>
                <Tickbox label={"已完成"} storageKey={"key_mon42"}/>
            </div>

        </div>

    );
}

export default Mon