import "./App.css"
import Tickbox from "./Tickbox";

function Fri() {
    const show1 = () => {
        alert('SIDNEY MYER ASIA CENTRE, Carrillo Gantner Theatre & Event Venue Floor:0.1 Room:B02')
    }
    const show2 = () => {
        alert('DAVID CARO BUILDING, Hercus Theatre Floor:2 Room:L105')
    }
    const show3 = () => {
        alert('OLD ARTS BUILDING, Public Lecture Theatre & Event Venue (PLT) Floor:1 Room:122')
    }
    return (
        <div className="">
            <div className="date-text">FRI</div>
            <div className="task" style={{ '--border-color': '#2fa6dd'}}>
                <div onClick={show1}>ADBS lecture 2</div> 
                <div>Time: 10:00-11:00</div>
                <Tickbox label={"进行中"} storageKey={"key_fri11"}/>
                <Tickbox label={"已完成"} storageKey={"key_fri12"}/>
            </div>

            <div className="task" style={{ '--border-color': '#2fa6dd'}}>
                <div onClick={show2}>CCC workshop</div> 
                <div>Time: 14:15-15:15</div>
                <Tickbox label={"进行中"} storageKey={"key_fri21"}/>
                <Tickbox label={"已完成"} storageKey={"key_fri22"}/>
            </div>

            <div className="task" style={{ '--border-color': '#2fa6dd'}}>
                <div onClick={show3}>DS lecture</div> 
                <div>Time: 15:15-17:15</div>
                <Tickbox label={"进行中"} storageKey={"key_fri31"}/>
                <Tickbox label={"已完成"} storageKey={"key_fri32"}/>
            </div>
            
        </div>

    );
}

export default Fri