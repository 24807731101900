import React, { useState, useEffect } from 'react';

const Tickbox = ({ label, storageKey }) => {
  const [isChecked, setChecked] = useState(
    () => localStorage.getItem(storageKey) === 'true'
  );

  useEffect(() => {
    localStorage.setItem(storageKey, isChecked);
  }, [isChecked, storageKey]);

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        {label}
      </label>
    </div>
  );
};

export default Tickbox;
